import Glide, {
} from '@glidejs/glide'

let totalSlides

let glide = new Glide('.glide', {
    type: 'carousel',
    // autoplay: 3000,
    gap: 0,    
    
})

glide.on('mount.before', function() {
    totalSlides = document.querySelectorAll(".glide__slide").length

    if(totalSlides == 1) {
        document.querySelector(".glide__arrows").style.display = "none"
    }
})

glide.on(['mount.after', 'run'], () => {
    document.querySelector(".pagination").innerHTML = glide.index + 1 + "/" + totalSlides
});


glide.mount()


window.onload = () => {

    const loader = document.querySelector(".loader")

    setTimeout(() => { 
        loader.style.opacity = 0; 
        loader.style.pointerEvents = "none"; 

        if(totalSlides > 1) {
            setTimeout(() => ( glide.go('>') ), 5000)
        }
    }, 2000)


};



